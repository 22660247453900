/**
 * Service for handling chat session operations
 * Communicates with the backend API to store, retrieve, update, and manage chat sessions
 */

export class ChatSessionsService {
  // Get the correct API base URL based on environment
  static getApiBaseUrl() {
    // Use relative URL to go through the BFF proxy
    return '/api/v1/chat-sessions';
  }
  
  static API_BASE_URL = this.getApiBaseUrl();
  static MAX_RETRIES = 2;
  static RETRY_DELAY = 800; // 0.8 second

  /**
   * Get the authentication headers including the token
   */
  static getAuthHeaders() {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return headers;
  }

  /**
   * Helper method to handle API response and errors
   */
  static async handleResponse(response) {
    const url = response.url || 'unknown URL';
    const contentType = response.headers.get('content-type');
    console.log(`Response for ${url} - status: ${response.status}, Content-Type: ${contentType}`);
    
    if (!response.ok) {
      if (contentType && contentType.includes('application/json')) {
        // Handle JSON error responses
        const errorJson = await response.json();
        const errorMessage = errorJson.detail || errorJson.message || `Error: ${response.status}`;
        throw new Error(errorMessage);
      } else {
        // Handle non-JSON responses (like HTML error pages)
        const errorText = await response.text();
        console.error(`Received non-JSON error response for ${url}:`, errorText.substring(0, 150) + '...');
        throw new Error(`Server returned a ${response.status} error. Please check if the backend server is running.`);
      }
    }
    
    // Check if response is empty for 204 responses
    if (response.status === 204) {
      return { success: true };
    }

    // Parse JSON for other successful responses
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      // Different warning message based on environment
      if (this.isProductionEnvironment()) {
        console.warn(`Production environment: Non-JSON response for ${url}. This is likely a login page or CDN routing issue.`);
      } else {
        console.warn(`Development environment: Non-JSON response for ${url}. Content-Type: ${contentType || 'undefined'}`);
      }
      
      const responseBody = await response.text();
      
      // In development, log the response body for debugging
      if (!this.isProductionEnvironment()) {
        console.log(`Response body (first 100 chars): ${responseBody.substring(0, 100)}`);
      }
      
      // Try to parse as JSON anyway if it looks like JSON
      if (responseBody.trim() && (responseBody.trim()[0] === '{' || responseBody.trim()[0] === '[')) {
        console.log(`Attempting to parse response as JSON anyway...`);
        try {
          return JSON.parse(responseBody);
        } catch (e) {
          console.error(`Failed to parse as JSON:`, e);
        }
      }
      
      // Default empty response for empty arrays
      if (responseBody.trim() === '[]') {
        return [];
      }
      
      return { success: true };
    }
  }

  /**
   * Get all chat sessions for the current user
   */
  static async getSessions() {
    try {
      console.log('Fetching sessions from:', this.API_BASE_URL);
      
      // Make the fetch request
      const response = await fetch(this.API_BASE_URL, {
        method: 'GET',
        headers: this.getAuthHeaders(),
        credentials: 'include',
      });
      
      // If we're in production and getting a text/html response, it's likely a login page
      if (this.isProductionEnvironment() && this.isHtmlResponse(response)) {
        console.log('Received HTML response in production - likely needs authentication');
        return [];
      }
      
      return await this.handleResponse(response);
    } catch (error) {
      console.error('Failed to fetch chat sessions:', error);
      // Return empty array instead of throwing to avoid breaking the UI
      return [];
    }
  }

  /**
   * Get a specific chat session by ID
   */
  static async getSession(sessionId) {
    try {
      console.log(`Fetching session ${sessionId} from: ${this.API_BASE_URL}/${sessionId}`);
      
      const response = await fetch(`${this.API_BASE_URL}/${sessionId}`, {
        method: 'GET',
        headers: this.getAuthHeaders(),
        credentials: 'include',
      });
      
      // Handle HTML responses in production
      if (this.isProductionEnvironment() && this.isHtmlResponse(response)) {
        console.log(`Received HTML response in production for session ${sessionId} - likely needs authentication`);
        throw new Error('Authentication required');
      }
      
      return await this.handleResponse(response);
    } catch (error) {
      console.error(`Failed to fetch chat session ${sessionId}:`, error);
      throw error;
    }
  }

  /**
   * Create a new chat session
   */
  static async createSession(sessionData) {
    try {
      console.log('Creating session at:', this.API_BASE_URL, 'with data:', sessionData);
      const response = await fetch(this.API_BASE_URL, {
        method: 'POST',
        headers: this.getAuthHeaders(),
        credentials: 'include',
        body: JSON.stringify(sessionData),
      });
      
      return await this.handleResponse(response);
    } catch (error) {
      console.error('Failed to create chat session:', error);
      throw error;
    }
  }

  /**
   * Update an existing chat session
   */
  static async updateSession(sessionId, sessionData) {
    try {
      console.log(`Updating session ${sessionId} at: ${this.API_BASE_URL}/${sessionId}`, 'with data:', sessionData);
      const response = await fetch(`${this.API_BASE_URL}/${sessionId}`, {
        method: 'PATCH',
        headers: this.getAuthHeaders(),
        credentials: 'include',
        body: JSON.stringify(sessionData),
      });
      
      return await this.handleResponse(response);
    } catch (error) {
      console.error(`Failed to update chat session ${sessionId}:`, error);
      throw error;
    }
  }

  /**
   * Delete a chat session
   */
  static async deleteSession(sessionId) {
    try {
      console.log(`Deleting session ${sessionId} from: ${this.API_BASE_URL}/${sessionId}`);
      const response = await fetch(`${this.API_BASE_URL}/${sessionId}`, {
        method: 'DELETE',
        headers: this.getAuthHeaders(),
        credentials: 'include',
      });
      
      if (!response.ok) {
        return this.handleResponse(response);
      }
      
      return { success: true };
    } catch (error) {
      console.error(`Failed to delete chat session ${sessionId}:`, error);
      throw error;
    }
  }

  /**
   * Helper method to retry operations with exponential backoff
   */
  static async withRetry(operation, retries = this.MAX_RETRIES) {
    let lastError;
    
    for (let attempt = 0; attempt <= retries; attempt++) {
      try {
        console.log(`Attempt ${attempt + 1} of ${retries + 1}`);
        return await operation();
      } catch (error) {
        lastError = error;
        
        // Don't retry if it's a 4xx error (client error)
        if (error.message?.includes('4') && 
            !error.message?.includes('429')) {
          console.log(`Not retrying due to 4xx error: ${error.message}`);
          throw error;
        }
        
        // Wait before next retry with exponential backoff
        if (attempt < retries) {
          const delay = this.RETRY_DELAY * Math.pow(2, attempt);
          console.log(`Retrying after ${delay}ms...`);
          await new Promise(resolve => 
            setTimeout(resolve, delay)
          );
        }
      }
    }
    
    console.error(`All ${retries + 1} attempts failed`);
    throw lastError;
  }

  /**
   * Save current chat to a new session
   */
  static async saveCurrentChat(title, messages, systemPrompt, llmType) {
    return this.withRetry(async () => {
      const session = await this.createSession({
        title: title || 'Untitled Chat',
        messages,
        system_prompt: systemPrompt,
        llm_type: llmType
      });
      console.log('Created new session:', session);
      return session;
    });
  }

  /**
   * Update messages in an existing session
   */
  static async appendMessages(sessionId, messages, appendMessages = true) {
    return this.withRetry(() => 
      this.updateSession(sessionId, {
        messages,
        append_messages: appendMessages
      })
    );
  }

  /**
   * Helper method to detect production environment
   */
  static isProductionEnvironment() {
    return window.location.hostname === 'infer.ing' || 
           window.location.hostname.includes('infer.ing');
  }

  /**
   * Helper method to check if response is HTML (likely login page)
   */
  static isHtmlResponse(response) {
    return response.headers.get('content-type')?.includes('text/html');
  }
} 