import React, { useState, useRef, useEffect } from 'react';
import styles from '../styles/chat.module.css';

const MessageInput = ({ 
  value: input, 
  onChange: setInput, 
  onSubmit: handleSubmit, 
  isLoading, 
  onStopGeneration: onStop, 
  onClearChat,
  systemPrompt,
  onSystemPromptChange: onSetSystemPrompt, 
  selectedLLM, 
  maxTokens = 8192, 
  onMaxTokensChange: onSetMaxTokens 
}) => {
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [promptInput, setPromptInput] = useState('');
  const [localMaxTokens, setLocalMaxTokens] = useState(maxTokens);
  const textareaRef = useRef(null);

  // Update local state when props change
  useEffect(() => {
    setPromptInput(systemPrompt || '');
  }, [systemPrompt]);

  useEffect(() => {
    setLocalMaxTokens(maxTokens);
  }, [maxTokens]);

  const handlePromptSubmit = () => {
    onSetSystemPrompt(promptInput);
    setShowPromptModal(false);
  };

  const handleSettingsSubmit = () => {
    onSetMaxTokens(localMaxTokens);
    setShowSettingsModal(false);
  };

  // Auto-resize the textarea based on content
  const autoResizeTextarea = () => {
    if (!textareaRef.current) return;

    // Use requestAnimationFrame to prevent forced reflows
    requestAnimationFrame(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;
      
      // Reset height to get the correct scrollHeight
      textarea.style.height = 'auto';
      
      // Calculate line height (approximate)
      const lineHeight = 24; // In pixels, matches line-height: 1.5 with font-size 16px
      
      // Get the scrollHeight and calculate number of lines
      const scrollHeight = textarea.scrollHeight;
      const numberOfLines = Math.ceil(scrollHeight / lineHeight);
      
      // Limit to 5 lines max
      const maxLines = 5;
      const limitedLines = Math.min(numberOfLines, maxLines);
      
      // Set the height based on content or max limit
      textarea.style.height = `${limitedLines * lineHeight}px`;
    });
  };

  // Update textarea height when input changes
  useEffect(() => {
    autoResizeTextarea();
  }, [input]);

  // Initial setup when component mounts
  useEffect(() => {
    autoResizeTextarea();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.inputForm}>
        <button
          type="button"
          className={styles.promptButton}
          onClick={() => setShowPromptModal(true)}
          disabled={isLoading}
          title="System Prompt"
        >
          P
        </button>
        {selectedLLM === 'claude' && (
          <button
            type="button"
            className={styles.settingsButton}
            onClick={() => setShowSettingsModal(true)}
            disabled={isLoading}
            title="Model Settings"
          >
            S
          </button>
        )}
        <textarea
          ref={textareaRef}
          name="chatMessage"
          id="chatMessage"
          value={input}
          onChange={setInput}
          className={styles.input}
          placeholder="Type your message..."
          disabled={isLoading}
          rows="1"
          onKeyDown={(e) => {
            // Let Enter key insert newline by default - no need to do anything here
            // Only Shift+Enter case might need special handling if any
          }}
        />
        {isLoading ? (
          <button
            type="button"
            className={`${styles.button} ${styles.stopButton}`}
            onClick={onStop}
          >
            Stop
          </button>
        ) : (
          <button
            type="submit"
            className={styles.button}
            disabled={isLoading}
          >
            Send
          </button>
        )}
      </form>

      {showPromptModal && (
        <div className={styles.promptOverlay}>
          <div className={styles.promptModal}>
            <h2>System Prompt</h2>
            <textarea
              className={styles.promptTextarea}
              value={promptInput}
              onChange={(e) => setPromptInput(e.target.value)}
              placeholder="Enter your system prompt..."
            />
            <div className={styles.promptButtons}>
              <button
                className={styles.button}
                onClick={() => setShowPromptModal(false)}
              >
                Cancel
              </button>
              <button
                className={styles.button}
                onClick={handlePromptSubmit}
              >
                Set Prompt
              </button>
            </div>
          </div>
        </div>
      )}

      {showSettingsModal && (
        <div className={styles.promptOverlay}>
          <div className={styles.promptModal}>
            <h2>Model Settings</h2>
            <div className={styles.settingField}>
              <label htmlFor="maxTokens">Max Tokens:</label>
              <input
                type="number"
                id="maxTokens"
                value={localMaxTokens}
                onChange={(e) => setLocalMaxTokens(parseInt(e.target.value))}
                min="1"
                max="4096"
              />
            </div>
            <div className={styles.promptButtons}>
              <button
                className={styles.button}
                onClick={() => setShowSettingsModal(false)}
              >
                Cancel
              </button>
              <button
                className={styles.button}
                onClick={handleSettingsSubmit}
              >
                Save Settings
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageInput; 