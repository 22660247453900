import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Override console.error to filter out model warming errors
const originalConsoleError = console.error;
console.error = function(...args) {
  // If this is a warming error message, don't show it in the UI
  const errorString = args.join(' ');
  if (
    errorString.includes('Failed to warm up') || 
    errorString.includes('connect ECONNREFUSED ::1:30001') ||
    errorString.includes('Warming up models') ||
    errorString.includes('tifa:latest') ||
    errorString.includes('deepseek:latest') ||
    errorString.includes('r1-1776:671b') ||
    errorString.includes('claude-3-7-sonnet-20250219')
  ) {
    // Silently ignore these specific errors
    return;
  }
  
  // Otherwise, pass through to the original console.error
  originalConsoleError.apply(console, args);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();