import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './styles/chat.module.css';
import Header from './components/Header';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';
import SessionList from './components/SessionList';
import { useChatMessages, useMessageStream } from './hooks';
import { sanitizeInput } from '../../utils/inputSanitizer';
import { AuthModal, useAuth } from '../Auth';
import { ChatSessionsService } from './services/chatSessionsService';

function Chat() {
  const { isAuthenticated, loading } = useAuth();
  const [input, setInput] = useState('');
  const [selectedLLM, setSelectedLLM] = useState('chatgpt');
  const [isSessionListVisible, setIsSessionListVisible] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const sessionListRef = useRef(null);
  const lastScrollHeight = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [maxTokens, setMaxTokens] = useState(8192);
  
  // Chat sessions state
  const [sessions, setSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [sessionsLoading, setSessionsLoading] = useState(false);
  const [sessionsError, setSessionsError] = useState(null);
  
  const {
    messages,
    isLoading: useChatMessagesLoading,
    setIsLoading: setUseChatMessagesLoading,
    addMessage,
    updateLastMessage,
    clearMessages
  } = useChatMessages();
  
  const { processStream } = useMessageStream();

  // Fetch chat sessions when the component mounts and user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchSessions();
    }
  }, [isAuthenticated]);

  // Fetch user's chat sessions
  const fetchSessions = async () => {
    // Only fetch sessions if the user is authenticated
    if (!isAuthenticated) {
      setSessions([]);
      return;
    }
    
    setSessionsLoading(true);
    setSessionsError(null);
    
    try {
      const fetchedSessions = await ChatSessionsService.getSessions();
      console.log('Fetched sessions:', fetchedSessions);
      setSessions(fetchedSessions);
      return fetchedSessions;
    } catch (error) {
      console.error('Error fetching sessions:', error);
      // Check if it's an authentication error
      if (error.message && (
          error.message.includes('Not authenticated') || 
          error.message.includes('401') ||
          error.message.includes('403')
      )) {
        // Authentication error - just set empty sessions
        setSessions([]);
      } else {
        // Other error - show error message
        setSessionsError('Failed to load chat history. Please try again.');
      }
      return [];
    } finally {
      setSessionsLoading(false);
    }
  };

  // Delete a chat session
  const handleDeleteSession = async (sessionId, event) => {
    // Stop event propagation to prevent session selection
    if (event) {
      event.stopPropagation();
    }
    
    if (!window.confirm('Are you sure you want to delete this chat session?')) {
      return;
    }
    
    try {
      await ChatSessionsService.deleteSession(sessionId);
      
      // If we're currently viewing the deleted session, clear the UI
      if (currentSessionId === sessionId) {
        startNewSession();
      }
      
      // Refresh the sessions list
      await fetchSessions();
    } catch (error) {
      console.error('Error deleting session:', error);
      setSessionsError('Failed to delete chat session. Please try again.');
    }
  };

  // Load a specific chat session
  const loadSession = async (sessionId) => {
    setIsLoading(true);
    setSessionsError(null);
    
    try {
      const session = await ChatSessionsService.getSession(sessionId);
      
      // Clear current chat and update with session data
      clearMessages();
      
      // Add messages from the session
      session.messages.forEach((msg) => {
        addMessage({
          content: msg.content,
          role: msg.role,
          id: Date.now() + Math.random()
        });
      });
      
      // Update session state
      setCurrentSessionId(session.id);
      setChatHistory(session.messages);
      setSystemPrompt(session.system_prompt || '');
      setSelectedLLM(session.llm_type);
      
      // Close session list after loading
      setIsSessionListVisible(false);
    } catch (error) {
      console.error('Error loading session:', error);
      setSessionsError('Failed to load chat session. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Helper function to format messages for saving
  const formatMessagesForSaving = useCallback(() => {
    console.log('Formatting messages for saving:', messages);
    return messages.map(msg => {
      if (msg.role === 'assistant') {
        // For assistant messages, prioritize the response field if available
        const content = msg.response || msg.content || '';
        return { role: msg.role, content };
      } else {
        return { role: msg.role, content: msg.content || '' };
      }
    }).filter(msg => msg.content); // Remove empty messages
  }, [messages]);

  // Helper function to generate title from messages
  const generateTitleFromMessages = useCallback(() => {
    const firstMsg = messages.find(msg => msg.role === 'user');
    return firstMsg ? 
      (firstMsg.content.length > 30 ? 
        firstMsg.content.substring(0, 27) + '...' : 
        firstMsg.content) : 
      'Untitled Chat';
  }, [messages]);

  // Save current chat as a new session
  const saveCurrentChat = async () => {
    if (messages.length === 0) return;
    
    // Ensure user is authenticated
    if (!isAuthenticated) {
      return;
    }
    
    try {
      const title = generateTitleFromMessages();
      const formattedMsgs = formatMessagesForSaving();
      console.log('Saving chat with formatted messages:', formattedMsgs);
      
      let session;
      if (currentSessionId) {
        // Update existing session
        session = await ChatSessionsService.updateSession(currentSessionId, {
          title,
          messages: formattedMsgs,
          system_prompt: systemPrompt,
          llm_type: selectedLLM
        });
      } else {
        // Create new session
        session = await ChatSessionsService.saveCurrentChat(
          title, 
          formattedMsgs, 
          systemPrompt, 
          selectedLLM
        );
        setCurrentSessionId(session.id);
      }
      
      console.log('Chat saved successfully:', session);
      const updatedSessions = await fetchSessions();
      
      // Ensure the current session is selected in the UI
      if (!currentSessionId && updatedSessions && updatedSessions.length > 0) {
        // Find the newly created session
        const newSession = updatedSessions.find(s => s.id === session.id) || updatedSessions[0];
        setCurrentSessionId(newSession.id);
      }
      
    } catch (error) {
      console.error('Error saving chat:', error);
      
      // User-friendly error message
      if (error.message && error.message.includes('Not authenticated')) {
        alert('Your session has expired. Please log in again to save your chat.');
      } else {
        alert('Failed to save chat. Please try again later.');
      }
    }
  };

  const handleScroll = useCallback(() => {
    if (!messageListRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
    const bottom = scrollHeight - scrollTop - clientHeight;
    setIsAtBottom(bottom < 50);
    lastScrollHeight.current = scrollHeight;
  }, []);

  const scrollToBottom = useCallback(() => {
    if (!messageListRef.current) return;
    
    // Use requestAnimationFrame to defer layout calculations to the next frame
    requestAnimationFrame(() => {
      const { scrollHeight, clientHeight } = messageListRef.current;
      
      // Only auto-scroll if we're at the bottom or it's a new message
      if (isAtBottom || scrollHeight > lastScrollHeight.current) {
        messageListRef.current.scrollTop = scrollHeight - clientHeight;
      }
      
      lastScrollHeight.current = scrollHeight;
    });
  }, [isAtBottom]);

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      // Use passive option to improve performance
      messageList.addEventListener('scroll', handleScroll, { passive: true });
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Add click outside handler for session list
  useEffect(() => {
    // This is to complement the Header's click handling
    // The Header component handles clicks on the history button and close button
    // This handles clicks on the main content area when the session list is open
    const handleClickOutside = (event) => {
      if (
        isSessionListVisible && 
        sessionListRef.current && 
        !sessionListRef.current.contains(event.target)
      ) {
        // Only close if clicked on main content area
        const mainContent = document.querySelector(`.${styles.mainContent}`);
        if (mainContent && mainContent.contains(event.target)) {
          setIsSessionListVisible(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isSessionListVisible]);

  const toggleSessionList = () => {
    setIsSessionListVisible((prev) => !prev);
  };

  const startNewSession = () => {
    clearMessages();
    setChatHistory([]);
    setInput('');
    setSystemPrompt('');
    setCurrentSessionId(null);
    setUseChatMessagesLoading(false);
    setIsLoading(false);
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
    
    // Refresh the sessions list to show any newly created sessions
    if (isAuthenticated) {
      fetchSessions();
    }
  };

  // Add stop generation handler
  const handleStopGeneration = () => {
    if (abortController) {
      abortController.abort();
      setIsLoading(false);
      setAbortController(null);
    }
  };

  // Update an existing session with the latest messages
  const updateCurrentSession = async () => {
    if (messages.length === 0 || !currentSessionId) return;
    
    try {
      const formattedMsgs = formatMessagesForSaving();
      const title = generateTitleFromMessages();
      
      await ChatSessionsService.updateSession(currentSessionId, {
        title,
        messages: formattedMsgs,
        system_prompt: systemPrompt,
        llm_type: selectedLLM,
        append_messages: false
      });
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedInput = sanitizeInput(input);
    
    if (!sanitizedInput || isLoading) return;

    setIsAtBottom(true);
    
    const userMessage = { 
      content: sanitizedInput, 
      role: 'user' 
    };
    const assistantMessage = { 
      content: '', 
      role: 'assistant' 
    };
    
    // Include previous history and add new messages
    const newHistory = [...chatHistory];
    if (messages.length > 0) {
      messages.forEach(msg => {
        // Only add completed messages to history, excluding thinking sections
        if (msg.role === 'assistant' && msg.response) {
          // For assistant messages, use the response field
          const isDuplicate = newHistory.some(
            historyMsg => 
              historyMsg.role === 'assistant' && 
              historyMsg.content === msg.response
          );
          if (!isDuplicate) {
            newHistory.push({
              role: 'assistant',
              content: msg.response
            });
          }
        } else if (msg.role === 'user' && msg.content) {
          // For user messages, use the content field
          const isDuplicate = newHistory.some(
            historyMsg => 
              historyMsg.role === 'user' && 
              historyMsg.content === msg.content
          );
          if (!isDuplicate) {
            newHistory.push({
              role: 'user',
              content: msg.content
            });
          }
        }
      });
    }
    
    // Add the new user message to history only if it's not already included
    const isInputDuplicate = newHistory.some(
      historyMsg => 
        historyMsg.role === 'user' && 
        historyMsg.content === sanitizedInput
    );
    
    if (!isInputDuplicate) {
      newHistory.push({
        role: 'user',
        content: sanitizedInput
      });
    }

    console.log('New history:', newHistory);
    setChatHistory(newHistory);
    
    addMessage(userMessage);
    addMessage(assistantMessage);
    setInput('');
    setUseChatMessagesLoading(true);

    const controller = new AbortController();
    setAbortController(controller);

    try {
      console.log('Sending request with history:', newHistory);
      
      // Prepare request body
      const requestBody = { 
        content: sanitizedInput,
        systemPrompt: systemPrompt,
        history: newHistory
      };
      
      // Add max_tokens for Claude model
      if (selectedLLM === 'claude') {
        requestBody.max_tokens = maxTokens;
      }
      
      const response = await fetch(`/api/v1/chat?llm=${selectedLLM}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        signal: controller.signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let finalAssistantMessage = '';
      
      await processStream(
        response,
        (message) => {
          finalAssistantMessage = message.response || finalAssistantMessage;
          updateLastMessage(msg => ({
            ...msg,
            thinking: message.thinking,
            response: message.response,
            id: Date.now()
          }));
        },
        (error) => {
          if (error instanceof Error && 
              (error.name === 'AbortError' || error.message?.includes('aborted'))) {
            return;
          }
          
          // Extract error message from error object
          const errorMessage = error.message || 
                              (typeof error === 'string' ? error : 'Sorry, I encountered an error. Please try again.');
          
          finalAssistantMessage = errorMessage;
          updateLastMessage(() => ({
            role: 'assistant',
            content: errorMessage,
            id: Date.now(),
            isError: true,
            isPaymentError: error.isPaymentError
          }));
        }
      );
      
      // After streaming is complete, we have the final message content
      // Add it to history for saving
      if (finalAssistantMessage) {
        const updatedHistory = [...newHistory, {
          role: 'assistant',
          content: finalAssistantMessage
        }];
        
        setChatHistory(updatedHistory);
        
        // Save with the complete history including the last response
        try {
          // After successful response, update the session if it exists
          if (currentSessionId) {
            await ChatSessionsService.updateSession(currentSessionId, {
              title: generateTitleFromMessages(),
              messages: updatedHistory,
              system_prompt: systemPrompt,
              llm_type: selectedLLM,
              append_messages: false
            });
          }
          // Auto-save the chat after each model response
          else if (isAuthenticated) {
            const newSession = await ChatSessionsService.saveCurrentChat(
              generateTitleFromMessages(),
              updatedHistory,
              systemPrompt,
              selectedLLM
            );
            
            if (newSession && newSession.id) {
              setCurrentSessionId(newSession.id);
              // Refresh the sessions list with the new session
              await fetchSessions();
            } else {
              // Fallback to the old approach if newSession doesn't have an id
              const sessions = await fetchSessions();
              if (sessions && sessions.length > 0) {
                setCurrentSessionId(sessions[0].id);
              }
            }
          }
        } catch (saveError) {
          console.error('Error saving chat after response:', saveError);
        }
      }
      
    } catch (error) {
      // Don't show error if aborted
      if (error.name !== 'AbortError') {
        console.error('Error:', error);
        
        // Extract error message
        const errorMessage = error.message || 'Error: Failed to get response';
        
        updateLastMessage(() => ({
          role: 'assistant',
          content: errorMessage,
          id: Date.now(),
          isError: true
        }));
      }
    } finally {
      setUseChatMessagesLoading(false);
      setAbortController(null);
    }
  };

  // Update clearMessages to also clear history
  const handleClearChat = () => {
    clearMessages();
    setChatHistory([]);
    setInput('');
    setUseChatMessagesLoading(false);
    setCurrentSessionId(null);
  };

  // If auth is still loading, show a loading indicator
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>Loading...</p>
      </div>
    );
  }

  // If user is not authenticated, show only the auth modal
  if (!isAuthenticated) {
    return <AuthModal onClose={() => {}} />;
  }

  // Regular chat UI for authenticated users
  return (
    <div className={styles.rootContainer}>
      {/* Session List Sliding Panel */}
      <div 
        className={`${styles.slidingPanel} ${isSessionListVisible ? styles.open : ''}`}
        ref={sessionListRef}
      >
        <SessionList
          sessions={sessions}
          loading={sessionsLoading}
          error={sessionsError}
          onSelectSession={loadSession}
          onDeleteSession={handleDeleteSession}
          currentSessionId={currentSessionId}
          onClose={() => setIsSessionListVisible(false)}
        />
      </div>
      
      {/* Main Content */}
      <div className={`${styles.mainContent} ${isSessionListVisible ? styles.shifted : ''}`}>
        <div className={styles.container}>
          <Header 
            toggleSessionList={toggleSessionList}
            startNewSession={startNewSession}
            isSessionListVisible={isSessionListVisible}
            selectedLLM={selectedLLM}
            onSelectLLM={setSelectedLLM}
            sessionListRef={sessionListRef}
            hasChatMessages={messages.length > 0}
          />
          <div className={styles.messageList} ref={messageListRef}>
            <MessageList 
              messages={messages} 
              isLoading={useChatMessagesLoading}
              onStopGeneration={handleStopGeneration}
            />
            <div ref={messagesEndRef} />
          </div>
          <MessageInput 
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onSubmit={handleSubmit}
            isLoading={isLoading || useChatMessagesLoading}
            onClearChat={handleClearChat}
            systemPrompt={systemPrompt}
            onSystemPromptChange={setSystemPrompt}
            maxTokens={maxTokens}
            onMaxTokensChange={setMaxTokens}
            selectedLLM={selectedLLM}
          />
        </div>
      </div>
    </div>
  );
}

export default Chat; 