// Helper functions for message processing and text manipulation

import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkStringify from 'remark-stringify'
import { visit } from 'unist-util-visit'

// A custom plugin to manipulate the AST to achieve similar effects
function remarkAdjustFormatting() {
  return (tree) => {
    // Add spacing before headings and lists by inserting empty paragraphs
    let { children } = tree;
    for (let i = children.length - 1; i >= 1; i--) {
      const node = children[i];
      if (node.type === 'heading' || node.type === 'list') {
        // Insert an empty paragraph before the heading/list for spacing
        children.splice(i, 0, { type: 'paragraph', children: [] });
      }
    }

    // Handle paragraphs with newlines
    visit(tree, 'paragraph', (node) => {
      // Look for text nodes with newlines
      if (node.children && node.children.length) {
        for (let i = 0; i < node.children.length; i++) {
          const child = node.children[i];
          if (child.type === 'text' && child.value.includes('\n')) {
            // Split by newline and create separate text nodes with line breaks
            const lines = child.value.split('\n');
            const newChildren = [];
            
            lines.forEach((line, index) => {
              if (index > 0) {
                // Add a line break element
                newChildren.push({ type: 'break' });
              }
              if (line) {
                newChildren.push({ type: 'text', value: line });
              }
            });
            
            // Replace the original text node with our new nodes
            node.children.splice(i, 1, ...newChildren);
            i += newChildren.length - 1; // Adjust index for the added nodes
          }
        }
      }
    });

    // Ensure proper spacing around links
    visit(tree, 'link', (node, index, parent) => {
      if (!parent || parent.type !== 'paragraph') return;
      // Replace the entire paragraph with a paragraph that has a line break before and after the link.
      parent.children = [
        { type: 'text', value: '\n' },
        node,
        { type: 'text', value: '\n' }
      ];
    });
  };
};

// Memoize processed content to avoid redundant processing
const processedContentCache = new Map();

// The main function using unified
export const processContent = (text) => {
  if (!text) return '';

  // Use cached result if available
  if (processedContentCache.has(text)) {
    return processedContentCache.get(text);
  }

  // First, ensure newlines are properly preserved for markdown
  let processedText = text;
  
  // Replace single newlines with double newlines to ensure proper markdown rendering
  // Only if they're not already doubled
  processedText = processedText.replace(/(?<!\n)\n(?!\n)/g, '\n\n');

  const file = unified()
    .use(remarkParse)
    .use(remarkAdjustFormatting)
    .use(remarkStringify, { 
      fences: true, // ensure code blocks remain fenced
      listItemIndent: 'one', // make list items look better
      bullet: '-', // consistent bullet style
      emphasis: '_', // consistent emphasis style
      strong: '*', // consistent strong style
      rule: '-', // consistent horizontal rule
      ruleSpaces: false, // don't include spaces in horizontal rules
      setext: false, // don't use setext headings
      // Ensure line breaks are preserved
      break: true,
      join: [
        () => (left, right) => {
          if (left.type === 'break') return false;
          return undefined;
        }
      ]
    })
    .processSync(processedText);

  const result = String(file);
  
  // Cache the result
  processedContentCache.set(text, result);
  
  return result;
};

export const extractTextFromChildren = (children) => {
  if (!children) return '';

  if (typeof children === 'string') {
    return children;
  }

  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join('');
  }

  if (children?.props?.children) {
    if (children.type === 'code') {
      // Handle code blocks
      return children.props.children.toString();
    } else {
      return extractTextFromChildren(children.props.children);
    }
  }

  return String(children).replace(/\[object Object\]/g, '');
};