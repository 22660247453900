import React from 'react';
import styles from '../styles/chat.module.css';

const LLMSelector = ({ selectedLLM, onSelect }) => {
  return (
    <>
      <li 
        className={selectedLLM === 'chatgpt' ? styles.activeOption : ''} 
        onClick={() => onSelect('chatgpt')}
      >
        ChatGPT
      </li>
      <li 
        className={selectedLLM === 'deepseek' ? styles.activeOption : ''} 
        onClick={() => onSelect('deepseek')}
      >
        DeepSeek
      </li>
      <li 
        className={selectedLLM === 'tifa' ? styles.activeOption : ''} 
        onClick={() => onSelect('tifa')}
      >
        Tifa
      </li>
      <li 
        className={selectedLLM === 'gemini' ? styles.activeOption : ''} 
        onClick={() => onSelect('gemini')}
      >
        Gemini
      </li>
      <li 
        className={selectedLLM === 'grok' ? styles.activeOption : ''} 
        onClick={() => onSelect('grok')}
      >
        Grok
      </li>
      <li 
        className={selectedLLM === 'r1-1776' ? styles.activeOption : ''} 
        onClick={() => onSelect('r1-1776')}
      >
        R1-1776
      </li>
      <li 
        className={selectedLLM === 'claude' ? styles.activeOption : ''} 
        onClick={() => onSelect('claude')}
      >
        Claude 3.7
      </li>
    </>
  );
};

export default LLMSelector;