export class StreamParser {
  constructor() {
    this.decoder = new TextDecoder();
    this.buffer = '';
    this.currentContent = { thinking: '', response: '' };
    this.isThinking = false;
    
    // Add a buffer size limit
    this.maxBufferSize = 5 * 1024 * 1024; // 5MB
    
    // Add metrics
    this.metrics = {
      totalChunks: 0,
      parseTime: 0,
      errorCount: 0
    };
  }

  parseChunk(chunk) {
    this.metrics.totalChunks++;
    const startTime = performance.now();
    
    // Check buffer size before adding new content
    if (this.buffer.length > this.maxBufferSize) {
      this.buffer = this.buffer.slice(-this.maxBufferSize/2);
    }
    
    const text = this.decoder.decode(chunk, { stream: true });
    this.buffer += text;
    
    const messages = [];
    const lines = this.buffer.split('\n');
    this.buffer = lines.pop() || '';

    for (const line of lines) {
      const trimmedLine = line.trim();
      if (!trimmedLine || !trimmedLine.startsWith('data: ')) continue;
      
      const data = trimmedLine.slice(6);
      if (data === '[DONE]') continue;

      try {
        const parsed = JSON.parse(data);
        
        if (parsed.choices && Array.isArray(parsed.choices)) {
          const content = parsed.choices[0]?.delta?.content;
          if (content !== undefined && content !== null) {
            if (content.includes('<think>')) {
              this.isThinking = true;
              const thoughtContent = content.replace('<think>', '');
              this.currentContent.thinking += thoughtContent;
            } else if (content.includes('</think>')) {
              this.isThinking = false;
              const thoughtContent = content.replace('</think>', '');
              this.currentContent.thinking += thoughtContent;
            } else {
              if (this.isThinking) {
                this.currentContent.thinking += content;
              } else {
                this.currentContent.response += content;
              }
            }
            messages.push({
              thinking: this.currentContent.thinking,
              response: this.currentContent.response
            });
          }
        } else if (parsed.content !== undefined) {
          this.currentContent.response += parsed.content;
          messages.push({
            thinking: '',
            response: this.currentContent.response
          });
        } else if (parsed.error) {
          this.metrics.errorCount++;
          
          // Format error for display
          let errorMsg;
          if (typeof parsed.error === 'string') {
            errorMsg = parsed.error;
          } else if (parsed.error && typeof parsed.error.message === 'string') {
            errorMsg = parsed.error.message;
          } else if (parsed.error && typeof parsed.error === 'object') {
            errorMsg = JSON.stringify(parsed.error);
          } else {
            errorMsg = 'An unknown error occurred';
          }
          
          messages.push({ 
            error: errorMsg,
            isPaymentError: this.isPaymentError(errorMsg)
          });
        }
      } catch (e) {
        this.metrics.errorCount++;
        console.error('Parse error:', {
          error: e,
          data,
          timestamp: new Date().toISOString()
        });
        messages.push({ error: 'Failed to parse response' });
      }
    }
    
    this.metrics.parseTime += performance.now() - startTime;
    return messages;
  }
  
  isPaymentError(errorMsg) {
    if (!errorMsg) return false;
    
    // Convert to string if it's not already
    const strError = String(errorMsg).toLowerCase();
    
    // Check for common payment/credit error patterns
    const paymentTerms = [
      '⚠️ payment required',
      'credit balance',
      'quota exceeded', 
      'payment required',
      'billing',
      'subscription',
      'plan limit',
      'usage limit',
      'exceeded your limit',
      'insufficient credit'
    ];
    
    return paymentTerms.some(term => strError.includes(term));
  }

  cleanup() {
    this.buffer = '';
    this.currentContent = { thinking: '', response: '' };
    this.isThinking = false;
  }

  getMetrics() {
    return {
      ...this.metrics,
      averageParseTime: this.metrics.totalChunks > 0 
        ? this.metrics.parseTime / this.metrics.totalChunks 
        : 0,
      errorRate: this.metrics.totalChunks > 0 
        ? this.metrics.errorCount / this.metrics.totalChunks 
        : 0
    };
  }
}