import { useCallback } from 'react';
import { StreamParser } from '../utils/streamParser';

export const useMessageStream = () => {
  const processStream = useCallback(async (response, onChunk, onError) => {
    if (!response?.body) {
      onError(new Error('Unable to connect to chat service'));
      return;
    }

    const streamParser = new StreamParser();

    try {
      const reader = response.body.getReader();
      
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const messages = streamParser.parseChunk(value);
        
        for (const message of messages) {
          if (message.error) {
            onError({
              message: message.error,
              isPaymentError: message.isPaymentError
            });
            continue;
          }
          
          onChunk(message);
        }
      }
    } catch (error) {
      const isAborted = error instanceof Error && 
        (error.name === 'AbortError' || error.message?.includes('aborted'));
      
      if (!isAborted) {
        onError({
          message: error.message || 'Connection interrupted. Please try again.',
          isPaymentError: false
        });
      }
    } finally {
      streamParser.cleanup();
    }
  }, []);

  return { processStream };
}; 