import React, { memo, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames';
import styles from '../styles/chat.module.css';
import { remarkPlugins, rehypePlugins, markdownComponents } from '../../../utils/markdownConfig';
import { processContent, extractTextFromChildren } from '../utils';
import { FaRegCopy } from 'react-icons/fa';

export const CopyButton = ({ code }) => {
  const handleCopy = async () => {
    try {
      let textContent = '';
      
      // If code is a DOM element (pre/code), get its text content directly
      if (code?.props?.children?.props?.children) {
        const codeContent = code.props.children.props.children;
        textContent = Array.isArray(codeContent) 
          ? codeContent.join('') 
          : String(codeContent);
      } else {
        // Fallback to previous method
        textContent = typeof code === 'string' 
          ? code 
          : extractTextFromChildren(code);
      }
      
      // Clean up the text while preserving structure
      const cleanText = textContent
        .replace(/\[object Object\]/g, '')
        .trim();
        
      await navigator.clipboard.writeText(cleanText);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <button 
      className={styles.copyButton}
      onClick={handleCopy}
      aria-label="Copy code"
    >
      <FaRegCopy size={16} />
    </button>
  );
};

const Message = memo(({ message, isLoading }) => {
  const showLoading = isLoading && message.role === 'assistant' && !message.thinking && !message.response;
  const isError = message.isError;
  
  // Check if the error is a payment/credit related error
  const isPaymentError = isError && 
    (message.isPaymentError ||
     message.content?.includes("⚠️ Payment Required") || 
     message.content?.includes("credit balance") || 
     message.content?.includes("quota exceeded") ||
     message.content?.includes("payment required") ||
     message.content?.includes("subscription"));

  const wrapperClassName = useMemo(() => cx(
    styles.messageWrapper,
    message.role === 'user' ? styles.userWrapper : styles.assistantWrapper
  ), [message.role]);

  const handleCopyMessage = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (err) {
      console.error('Failed to copy message:', err);
    }
  };

  return (
    <div className={wrapperClassName}>
      {/* Thinking section */}
      {message.thinking && message.thinking.trim() && (
        <div className={cx(styles.message, styles.thinkingMessage)}>
          <div className={styles.messageHeader}>
            <button 
              className={styles.copyButton}
              onClick={() => handleCopyMessage(message.thinking)}
              aria-label="Copy thinking"
            >
              <FaRegCopy size={16} />
            </button>
          </div>
          <div className={styles.messageContent}>
            <ReactMarkdown>
              {message.thinking}
            </ReactMarkdown>
          </div>
        </div>
      )}

      {/* Message content with copy button */}
      {(message.response || message.content) && (
        <div className={cx(styles.message, styles.assistantMessage)}>
          <div className={styles.messageHeader}>
            <button 
              className={styles.copyButton}
              onClick={() => handleCopyMessage(message.response || message.content)}
              aria-label="Copy message"
            >
              <FaRegCopy size={16} />
            </button>
          </div>
          <div className={styles.messageContent}>
            <ReactMarkdown 
              rehypePlugins={rehypePlugins}
              remarkPlugins={remarkPlugins}
              components={markdownComponents}
            >
              {processContent(message.response || message.content)}
            </ReactMarkdown>
          </div>
        </div>
      )}
      
      {/* Loading state */}
      {showLoading && (
        <div className={cx(styles.message, styles.assistantMessage)}>
          <div className={styles.messageContent}>
            <div className={styles.loadingDots}>
              <span>.</span><span>.</span><span>.</span>
            </div>
          </div>
        </div>
      )}
      
      {/* Error state */}
      {isError && (
        <div className={cx(
          styles.message, 
          styles.errorMessage,
          { [styles.paymentErrorMessage]: isPaymentError }
        )}>
          <div className={styles.messageContent}>
            {message.content}
          </div>
        </div>
      )}
    </div>
  );
});

Message.displayName = 'Message';

export default Message; 