import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import { AuthService } from '../services/authService';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to check for existing token and fetch user on initial load
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        setLoading(true);
        // Check if we have a token in localStorage
        const token = localStorage.getItem('token');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }
        
        // We have a token, try to get the user data
        const userData = await AuthService.getUser();
        setUser(userData);
        setIsAuthenticated(true);
      } catch (error) {
        console.warn('No active session found or token expired');
        // Clean up any invalid tokens
        localStorage.removeItem('token');
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };
    initializeAuth();
  }, []);

  // Login function
  const login = useCallback(async (credentials) => {
    try {
      setLoading(true);
      const userData = await AuthService.login(credentials);
      setUser(userData);
      setIsAuthenticated(true);
      return userData;
    } catch (error) {
      console.error('Login failed:', error);
      throw error; // Handle errors in UI
    } finally {
      setLoading(false);
    }
  }, []);

  // Logout function
  const logout = useCallback(async () => {
    try {
      setLoading(true);
      await AuthService.logout();
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout failed:', error);
      // Even if the server logout fails, clear the local session
      setUser(null);
      setIsAuthenticated(false);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  // Register function
  const register = useCallback(async (credentials) => {
    try {
      setLoading(true);
      const userData = await AuthService.register(credentials);
      setUser(userData);
      setIsAuthenticated(true);
      return userData;
    } catch (error) {
      console.error('Registration failed:', error);
      // Make sure we reset the auth state on error
      setUser(null);
      setIsAuthenticated(false);
      
      // Remove any potentially corrupted token
      localStorage.removeItem('token');
      
      // Re-throw the error for UI handling
      throw error;
    } finally {
      // Always reset loading state regardless of error or authentication status
      setLoading(false);
    }
  }, []);

  const value = {
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    register,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Hook to access authentication context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};