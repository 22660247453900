import React, { useState } from 'react';
import styles from '../styles/auth.module.css';
import { useAuth } from '../contexts/AuthContext';

const AuthModal = ({ onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, register } = useAuth();
  
  // Determine if this is the main login screen (no close function)
  const isMainLoginScreen = typeof onClose !== 'function' || onClose === (() => {});

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget && !isMainLoginScreen) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      if (isLogin) {
        await login({ email, password });
        if (!isMainLoginScreen) {
          onClose();
        }
      } else {
        try {
          await register({ email, password });
          if (!isMainLoginScreen) {
            onClose();
          }
        } catch (registrationError) {
          // Handle registration-specific errors
          console.error('Registration specific error:', registrationError);
          setError(registrationError.message || 'Registration failed. Please try again.');
          setLoading(false);
          return; // Exit early, don't close modal or attempt further actions
        }
      }
    } catch (error) {
      console.error('Auth error:', error);
      setError(error.message || 'Authentication failed. Please try again.');
    } finally {
      // Ensure loading state is always reset
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.modalOverlay} ${isMainLoginScreen ? styles.mainScreen : ''}`} onClick={handleOverlayClick}>
      <div className={styles.modalContent}>
        {/* Header */}
        <div className={styles.modalHeader}>
          <img 
            src={process.env.PUBLIC_URL + '/favicon.ico'} 
            alt="Logo" 
            className={styles.logo} 
          />
          {!isMainLoginScreen && (
            <button className={styles.closeButton} onClick={onClose}>&times;</button>
          )}
        </div>

        {/* Tabs */}
        <div className={styles.authTabs}>
          <button 
            className={`${styles.tabButton} ${isLogin ? styles.active : ''}`}
            onClick={() => setIsLogin(true)}
          >
            Login
          </button>
          <button 
            className={`${styles.tabButton} ${!isLogin ? styles.active : ''}`}
            onClick={() => setIsLogin(false)}
          >
            Register
          </button>
        </div>

        {/* Form Fields */}
        <form className={styles.form} onSubmit={handleSubmit}>
          <input 
            type="email" 
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            placeholder="Email" 
            className={styles.input}
            required
          />
          <input 
            type="password" 
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            placeholder="Password" 
            className={styles.input}
            required
          />
          
          {error && <div className={styles.error}>{error}</div>}
          
          {isLogin && (
            <div className={styles.formFooter}>
              <label className={styles.checkboxLabel}>
                <input 
                  type="checkbox" 
                  id="rememberMe"
                  name="rememberMe"
                  autoComplete="remember-me"
                  className={styles.checkbox} 
                />
                Remember me
              </label>
              <a href="/forgot-password" className={styles.forgotPassword}>
                Forgot Password?
              </a>
            </div>
          )}

          <button 
            type="submit" 
            name="submitButton" 
            className={styles.submitButton}
            disabled={loading}
          >
            {loading 
              ? (isLogin ? 'Logging in...' : 'Creating Account...') 
              : (isLogin ? 'Login' : 'Create Account')}
          </button>
        </form>

        {/* Footer */}
        <div className={styles.modalFooter}>
          <small>
            By continuing, you agree to our 
            <a href="/terms">Terms of Service</a> and 
            <a href="/privacy">Privacy Policy</a>
          </small>
        </div>
      </div>
    </div>
  );
};

export default AuthModal; 