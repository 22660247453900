import axios from 'axios';

export class AuthService {
  // Base URL for the backend API - use relative URLs to go through our BFF
  static getApiBaseUrl() {
    // Always use relative URLs in production to leverage the BFF pattern
    if (window.location.hostname === 'infer.ing' || window.location.hostname.includes('infer.ing')) {
      return '';  // Empty base URL for relative paths
    }
    
    // For local development, we might still connect directly to the backend
    if (import.meta.env && import.meta.env.VITE_API_URL) {
      return import.meta.env.VITE_API_URL;
    }
    
    // Default to localhost backend
    return 'http://localhost:8000';
  }
  
  static API_BASE_URL = this.getApiBaseUrl();
  
  // Create axios instance with default config
  static api = axios.create({
    baseURL: this.API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    timeout: 10000, // 10 second timeout
    validateStatus: (status) => {
      return status >= 200 && status < 300; // Default is 200-300
    }
  });

  // Configure request interceptor to add auth token if available
  static configureInterceptors() {
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
      }
    );
    
    // Response interceptor for global error handling
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.error('Response interceptor error:', error);
        return Promise.reject(error);
      }
    );
  }

  // Call this method to initialize interceptors
  static {
    this.configureInterceptors();
  }

  static async login(credentials) {
    try {
      const response = await this.api.post('/api/v1/auth/login', credentials);
      
      // Store the token in localStorage for session persistence
      localStorage.setItem('token', response.data.access_token);
      return this.getUser(); // Fetch user data with the new token
    } catch (error) {
      console.error('Login error:', error);
      throw new Error(error.response?.data?.detail || 'Invalid login credentials');
    }
  }

  static async logout() {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');
      
      if (!token) {
        // No token, no need to call logout endpoint
        return;
      }

      // Set the auth header for this request
      const config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };

      await this.api.post('/api/v1/auth/logout', {}, config);
      
      // Always remove token on logout regardless of server response
      localStorage.removeItem('token');
    } catch (error) {
      console.error('Logout error:', error);
      // Still remove token on error
      localStorage.removeItem('token');
      throw error;
    }
  }

  static async register(credentials) {
    try {
      console.log('Registering user:', credentials);
      const response = await this.api.post('/api/v1/auth/register', credentials);
      
      if (!response.data || !response.data.access_token) {
        throw new Error('Invalid server response - no token received');
      }
      
      // Store the token in localStorage
      localStorage.setItem('token', response.data.access_token);
      
      // Get user data
      try {
        const userData = await this.getUser();
        return userData;
      } catch (userError) {
        console.error('Error fetching user after registration:', userError);
        localStorage.removeItem('token');
        throw new Error('Registration succeeded but failed to get user data');
      }
    } catch (error) {
      console.error('Registration error:', error);
      // Make sure we clean up any token if an error occurs
      localStorage.removeItem('token');
      
      // More detailed error handling based on the response
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400 && error.response.data?.detail === "Email already registered") {
          throw new Error('This email is already registered. Please use a different email or try logging in.');
        } else {
          throw new Error(error.response.data?.detail || 'Registration failed');
        }
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error('No response from server. Please check your internet connection and try again.');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('Registration failed: ' + error.message);
      }
    }
  }

  static async getUser() {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('No authentication token found');
      }

      // Set the auth header for this request
      const config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };

      const response = await this.api.get('/api/v1/auth/user', config);
      return response.data;
    } catch (error) {
      console.error('Get user error:', error);
      
      // If unauthorized, clear the token
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
      
      throw new Error('Failed to fetch user data');
    }
  }
}