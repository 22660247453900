import React, { forwardRef } from 'react';
import { FaSpinner, FaTrash } from 'react-icons/fa';
import styles from '../styles/sessionList.module.css';

const SessionList = forwardRef(({ 
  sessions = [],
  loading, 
  onSelectSession, 
  onDeleteSession,
  currentSessionId,
  onClose,
  error
}, ref) => {
  if (error) {
    return (
      <div className={styles.sessionList} ref={ref}>
        <div className={styles.header}>
          <h3>Chat History</h3>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.errorState}>
          <p>{error}</p>
          <button 
            className={styles.retryButton}
            onClick={() => onSelectSession && onSelectSession()}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.sessionList} ref={ref}>
        <div className={styles.header}>
          <h3>Chat History</h3>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.loadingContainer}>
          <FaSpinner className={styles.spinner} />
          <p>Loading sessions...</p>
        </div>
      </div>
    );
  }

  const sessionsArray = Array.isArray(sessions) ? sessions : [];

  if (sessionsArray.length === 0) {
    return (
      <div className={styles.sessionList} ref={ref}>
        <div className={styles.header}>
          <h3>Chat History</h3>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.emptyState}>
          <p>No chat history yet.</p>
          <p>Your conversations will appear here.</p>
        </div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric',
      hour12: true
    });
  };

  return (
    <div className={styles.sessionList} ref={ref}>
      <div className={styles.header}>
        <h3>Chat History</h3>
        <button className={styles.closeButton} onClick={onClose}>×</button>
      </div>
      <div className={styles.sessionItems}>
        {sessionsArray.map((session) => (
          <div 
            key={session.id} 
            className={`${styles.sessionItem} ${currentSessionId === session.id ? styles.active : ''}`}
            onClick={() => onSelectSession(session.id)}
          >
            <div className={styles.sessionInfo}>
              <div className={styles.sessionTitle}>
                {session.title || 'Untitled Chat'}
              </div>
              <div className={styles.sessionMeta}>
                <span className={styles.sessionDate}>
                  {formatDate(session.created_at)}
                </span>
                {session.llm_type && (
                  <span className={styles.sessionModel}>
                    {session.llm_type}
                  </span>
                )}
              </div>
            </div>
            {onDeleteSession && (
              <button 
                className={styles.deleteButton}
                onClick={(e) => onDeleteSession(session.id, e)}
                aria-label="Delete session"
              >
                <FaTrash size={14} />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

export default SessionList; 