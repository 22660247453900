/**
 * Sanitizes user input to prevent XSS and other injection attacks
 * @param {string} input - Raw user input
 * @returns {string} Sanitized input string
 */
export const sanitizeInput = (input) => {
  if (typeof input !== 'string') {
    return '';
  }

  // Remove any HTML tags
  const stripHtml = input.replace(/<[^>]*>/g, '');
  
  // Remove any null bytes
  const stripNull = stripHtml.replace(/\0/g, '');
  
  // Normalize whitespace (but preserve newlines)
  const normalizeSpace = stripNull
    .split('\n')
    .map(line => line.replace(/\s+/g, ' ').trim())
    .join('\n')
    .trim();
  
  // Limit input length (e.g., 2000 characters)
  const truncated = normalizeSpace.slice(0, 2000);
  
  return truncated;
}; 